body {
  font-family: "CircularStd Black";
}
@font-face {
  font-family: "CircularStd Black";
  src: url("/fonts/CircularStd-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd Black Italic";
  src: url("/fonts/CircularStd-BlackItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "CircularStd Bold";
  src: url("/fonts/CircularStd-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd Bold Italic";
  src: url("/fonts/CircularStd-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd Medium";
  src: url("/fonts/CircularStd-Medium.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd Medium Italic";
  src: url("/fonts/CircularStd-MediumItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
nav {
  height: 10vh;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;

  .img-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .img-container > img {
    width: 130px;
    height: auto;
    margin: auto 0;
  }
  .nav-links {
    display: flex;
    list-style: none;
    width: 30%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    li {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &:hover {
        background-color: #fec91e;
        cursor: pointer;
      }
    }
    a {
      color: #000;
      text-decoration: none;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  nav {
    position: relative;
    z-index: 9;
    .hamburger {
      position: fixed;
      cursor: pointer;
      right: 5%;
      top: 5%;
      transform: translate(-5%, -50%);
      z-index: 10;

      .line {
        width: 30px;
        height: 3px;
        margin: 5px;
        background: azure;
      }
    }

    .nav-links {
      position: fixed;
      background: #4dcce5;
      height: 100vh;
      width: 100%;
      flex-direction: column;
      clip-path: circle(100px at 90% -10%);
      -webkit-clip-path: circle(100px at 90% -10%);
      transition: all 1s ease-out;
      pointer-events: none;
      opacity: 0;

      &.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
        opacity: 1;
      }

      li {
        opacity: 0;

        a {
          font-size: 25px;
        }

        &:nth-child(1) {
          transition: all 0.5s ease 0.2s;
        }
        &:nth-child(2) {
          transition: all 0.5s ease 0.4s;
        }
        &:nth-child(3) {
          transition: all 0.5s ease 0.6s;
        }
        &.fade {
          opacity: 1;
        }
      }
    }
  }
}

.talents-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  margin: 60px 0;
}

.card {
  position: relative;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  outline: 0;
  max-width: 350px;
  font-size: 16px;
  min-height: 400px;

  img {
    margin: 1rem 0;
    height: 140px;
    width: 140px;
    &.feature-img {
      border-radius: 50%;
    }
  }
  .card-body-under {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2px;

    p {
      text-align: center;
    }

    .badges {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  }
  .card-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .card-body-upper {
    display: none;
  }
  &:hover > .hashtag {
    display: none;
  }
  &:hover > .card-body-under {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    filter: blur(8px);
    opacity: 0.3;
  }
  &:hover .hashtag-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: Move-up 0.5s;
  }
  &:hover > .card-body-upper {
    position: relative;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &:hover > .card-body-upper > .card-title,
  &:hover > .card-body-upper > .social-icons,
  &:hover > .card-body-upper > .card-texty {
    animation-name: Appear-fade-in;
    animation-duration: 0.75s;
  }

  @keyframes Move-up {
    from {
      transform: translateY(200%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes Appear-fade-in {
    0%,
    33% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin: 5px 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease-in;
      background: white;
      border-radius: 50%;
      height: 38px;
      width: 38px;
      text-decoration: none;

      &:hover {
        color: azure;
      }
    }
  }
}

#searchBar {
  margin: 4rem auto 0 auto;
  height: 4rem;
  border-radius: 2rem;
  background-color: #f4f4f9;
  padding: 1rem;
  border: #e5e5f1 2px solid;

  .searchInput {
    color: #9b9b9b;
    border: 0;
    outline: 0;
    background: none;
    line-height: 2rem;
    padding: 0 1rem;
    width: 80%;
  }

  .search_icon {
    height: 2rem;
    width: 2rem;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #9b9b9b;
    text-decoration: none;
    & select {
      appearance: none;
      background-color: #f4f4f9;
      outline: none;
      border: none;
    }
  }
}
.auto-complete > button {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  background-color: #f4f4f9;
  border: none;
  box-shadow: none;
}

.footer {
  font-size: 14px;
  background-color: #fff;
  .row {
    height: 20vh;
  }
  .row > div {
    height: 100%;
    display: flex;
    color: #000;
    flex-direction: column;
    text-align: center;
    padding: 2rem 0 2rem 2rem;
  }
}
.page-link.active {
  background-color: #007bff;
  color: white;
  outline-style: none;
}

.speech-bubble {
  position: relative;
  background: #00aabb;
  border-radius: 0.4em;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 42px solid transparent;
    border-top-color: #00aabb;
    border-bottom: 0;
    margin-left: -42px;
    margin-bottom: -42px;
  }
}
